<template>
  <div class="signup-comp">
    <!-- pc -->
    <div class="pc signup-container">
      <div class="signup-wrapper">
        <div class="subtitle3 main" style="margin-bottom: 16px">연동완료</div>
        <img src="/static/img/img_success.svg" style="margin-bottom:40px" />
        <div class="body2-bold primary">Welcome!</div>
        <div class="body2-medium sub2" style="margin-top:8px">기존계정과 자동 연동되었습니다.<br />환영합니다.</div>
        <div style="margin:40px 0;width:100%">
          <button class="button is-primary body2-medium" style="width:100%" @click="clickStart">
            원래 페이지로 돌아가기
          </button>

          <router-link to="/home">
            <button class="button is-primary-light body2-medium" style="width:100%;margin-top:12px">홈으로</button>
          </router-link>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="mobile signup-container">
      <div class="signup-wrapper">
        <div class="h7 main" style="margin-bottom: 16px">연동완료</div>
        <img src="/static/img/img_success.svg" style="margin-bottom:32px;width:140px;height:140px" />
        <div class="body2-bold primary">Welcome!</div>
        <div class="body4 sub2" style="margin-top:8px">기존계정과 자동 연동되었습니다. 환영합니다.</div>
        <div style="margin:40px 0;width:100%">
          <button class="button is-primary body2-medium" style="height:48px;width:100%;" @click="clickStart">
            원래 페이지로 돌아가기
          </button>
          <router-link to="/home">
            <button class="button is-primary-light body2-medium" style="height:48px;width:100%;margin-top:12px">
              홈으로
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MergeDone',
  created() {},
  data() {
    return {};
  },
  methods: {
    clickStart() {
      if (this.$store.getters.prevPath === '') {
        this.$router.replace('/home');
      } else {
        let path = this.$store.getters.prevPath;
        this.$store.commit('setPrevPath', '');
        this.$router.replace(path);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.signup-container
  text-align center

.signup-wrapper
  border 1px solid $gray2
  border-radius 12px
  background-color white
  padding 40px
  margin-top 24px

@media (min-width:1025px)
  .signup-container
    margin-top 80px
    width 448px

@media (max-width:1024px)
  .signup-comp
    width 100%
    padding 40px 16px

  .signup-container
    margin 40px 16px
  .signup-wrapper
    padding 32px 20px
</style>
